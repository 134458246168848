import $ from "jquery";
import "../wrapper/select2";

export default function(){
  $(document).ready( function() {
    $('.practice-select-with-search').select2({
      language: 'de'
    });
  });
}
