import $ from "jquery";
import Drop from "tether-drop";

export default function (unique_id) {
  $(document).ready(function(){
    if (window.innerWidth >= 1030) {
      var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      if (supportsTouch) {
        bad_touch_link = $(`.navigation_item.${unique_id} a`);
        if (bad_touch_link.hasClass("real_parent")) {
          // This means that the outer navigation item links to a real page and not just to the first child page
          // Thus we copy the link to the dropdown.
          copy_link = bad_touch_link.clone();
          $(`.navigation_sub-navigation-menu.${unique_id}`).prepend($("<div>").addClass("navigation_sub-navigation-item").append(copy_link));
        }
        bad_touch_link.attr("href", null);
      }

      new Drop({
        target: $(`.navigation_item.${unique_id}`)[0],
        content: $(`.navigation_sub-navigation-menu.${unique_id}`)[0],
        openOn: 'hover',
        remove: true,
        tetherOptions: {
          attachment: 'top left',
          targetAttachment: 'bottom left',
          constraints: [
            {
              to: 'window',
              attachment: 'both'
            }
          ]
        }
      });
    }
    else {
      bad_touch_link = $(`.navigation_item.${unique_id} a`);
      if (bad_touch_link.hasClass("real_parent")) {
        // This means that the outer navigation item links to a real page and not just to the first child page
        // Thus we copy the link to the dropdown.
        copy_link = bad_touch_link.clone();
        $(`.navigation_sub-navigation-menu.${unique_id}`).prepend($("<div>").addClass("navigation_sub-navigation-item").append(copy_link));
      }
      bad_touch_link.attr("href", null);

      drop = new Drop({
        target: $(`.navigation_item.${unique_id}`)[0],
        content: $(`.navigation_sub-navigation-menu.${unique_id}`)[0],
        openOn: 'click',
        remove: true,
        tetherOptions: {
          attachment: 'top left',
          targetAttachment: 'bottom right',
          constraints: [
            {
              to: 'window',
              attachment: 'both'
            }
          ]
        }
      });
    }
  });
}
