import $ from "jquery";
import Drop from "tether-drop";

export default function(){
  $(document).ready(function(){
    new Drop({
      target: $('.header_login')[0],
      content: $('.login_signed-in-menu')[0],
      position: 'bottom right',
      openOn: 'click',
      remove: true
    });
  });
}
