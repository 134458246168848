<template>
  <div ref="root" class="components__cnt-plot_situation_amount"></div>
</template>

<script>
import Plotly from "wrapper/plotly";
export default {
  mounted() {
    const patienten = {
      x: [
        "0 bis unter 1",
        "1 bis unter 3",
        "3 bis unter 6",
        "6 bis unter 10",
        "10 bis unter 18",
      ],
      y: [1726, 7587, 10694, 12190, 15311],
      marker: { color: "#CBBE9E" },
      name: "chronisch erkrankt",
      type: "bar",
      textposition: "auto",
      hoverinfo: "y",
    };
    const vorstellungen = {
      x: [
        "0 bis unter 1",
        "1 bis unter 3",
        "3 bis unter 6",
        "6 bis unter 10",
        "10 bis unter 18",
      ],
      y: [16755, 44677, 37420, 24393, 22705],
      marker: { color: "#E79E6B" },
      name: "nicht chronisch erkrankt",
      type: "bar",
      textposition: "auto",
      hoverinfo: "y",
    };
    const layout = {
      barmode: "stack",
      title:
        "<b>Abb.2</b> <br>Vorstellungsanteil von Patienten<br> mit chronischer Grunderkrankung",
      yaxis: {
        hoverformat: ".1%",
        tickformat: ".0%",
      },
    };
    patienten.text = patienten.y.slice();
    vorstellungen.text = vorstellungen.y.slice();
    for (var i = 0; i < patienten.y.length; i++) {
      const pat = patienten.y[i];
      const vor = vorstellungen.y[i];
      patienten.y[i] = pat / (pat + vor) + "%";
      vorstellungen.y[i] = vor / (pat + vor) + "%";
    }
    patienten.y;
    Plotly.newPlot(this.$refs["root"], [patienten, vorstellungen], layout, {
      modeBarButtonsToRemove: ["sendDataToCloud"],
    });
  },
};
</script>
