import $ from "jquery";
import Vue from "vue";
import ImportAll from "../utils/import_all";

// Load all vue-components
const components = {};
ImportAll(components , require.context('../vue/components/' , true, /\.vue$/));
Object.keys(components).forEach(key => Vue.component(key, components[key]));

// Create vue instance
export default function(selector){
  $(document).ready(function(){
    const current_vue = new Vue({
      el: selector,
      data: gon
    });
  });
}
