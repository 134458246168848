<template>
  <div class="vue__slide-show"></div>
</template>

<script>
import { toRefs } from "@vue/composition-api";
export default {
  props: {
    slideshowId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const {} = toRefs(props);
    return {};
  },
};
</script>
