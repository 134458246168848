import Noty from "noty";

export default function(message){
  new Noty({
    text: message || "",
    layout: 'bottom',
    timeout: 5000,
    type: 'success'
  }).show();
};
