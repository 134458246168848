import SharedPubSubInterface from "vue/applications/shared/pubsub/pub-sub-interface";

import Topics from "./topics";

export default class PubSubInterface extends SharedPubSubInterface {
  constructor() {
    super();
  }

  get topics(): typeof Topics {
    return Topics;
  }
}
