import $ from "jquery";

export default function (uuid) {
  $(document).ready(function () {
    $(`.content-${uuid}`).hide();
    $(`.toggle-${uuid}`).click(function () {
      $('.toggle-symptom-adder').hide(400);
      $(`.content-${uuid}`).show(400);
      $('input.selected-objectives-input').val($(`.toggle-${uuid}`).data('apt'));
    })
  })
};