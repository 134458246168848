// Helper function to import a subdirectory into
// paedcompenda."key_name" or directly into key_name (if you give a hash)
// It always imports the default module
export default function(
  keyOrStore: string | { [key: string]: VueApplication },
  webpackContext: __WebpackModuleApi.RequireContext,
) {
  let result: { [key: string]: VueApplication };
  if (typeof keyOrStore === "string") {
    window.paedcompenda = window.paedcompenda || {};
    window.paedcompenda[keyOrStore] = window.paedcompenda[keyOrStore] || {};
    result = window.paedcompenda[keyOrStore];
  } else {
    result = keyOrStore;
  }

  webpackContext.keys().forEach((key) => {
    const moduleName = key
      .split("/")
      .slice(-1)[0]
      .split(".")[0];
    try {
      result[moduleName] = webpackContext(key).default;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Could not load module '${key}'.`, e);
    }
  });
}
