import $ from "jquery";
import Drop from "tether-drop";

export default function(){
  $(document).ready(function(){
    var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    if(!supportsTouch || window.innerWidth >= 500){
      new Drop({
        target: $('.header_login')[0],
        content: $('.login_sign-in-menu')[0],
        position: 'bottom right',
        openOn: 'click',
        remove: true
      });
    }
  });
}
