import PubSub from "pubsub-js";

import Topics from "./topics";

// Interface to the pubsub library.
// See pubsub-js library for intormation
// Do not forget to call cleanup function to remove event listeners
export default class PubSubInterface {
  private subscriptions: PubSubJS.SubscriptionToken[] = [];
  constructor() {
    this.subscriptions = [];
  }

  public subscribe(
    topic: symbol,
    func: PubSubJS.SubscriptionFunction,
  ): PubSubJS.SubscriptionToken | null {
    // We want to log subscriptions
    // tslint:disable-next-line: no-console
    console.log(`Subscribe on ${topic.toString()}`);
    const subscription = PubSub.subscribe(topic, func);
    if (typeof subscription === "string") {
      this.subscriptions.push(subscription);
      return subscription;
    }
    return null;
  }

  public unsubscribe(
    tokenOrFunc: PubSubJS.SubscriptionToken | PubSubJS.SubscriptionFunction,
  ) {
    return PubSub.unsubscribe(tokenOrFunc);
  }

  public publish(topic: symbol, data: any) {
    // We want to log publishs for now
    // tslint:disable-next-line: no-console
    console.log(`Publish on ${topic.toString()}`);
    return PubSub.publish(topic, data);
  }

  get topics(): typeof Topics {
    return Topics;
  }

  public cleanup(): void {
    while (this.subscriptions.length > 0) {
      const subscription = this.subscriptions.pop();
      // Unnec sanity check to satisfy type
      if (subscription) this.unsubscribe(subscription);
    }
  }
}
