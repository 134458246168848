import $ from "jquery";
import moment from "moment";
import Pikaday from "pikaday";

export default function(unique_id, no_max_date = false){
  $(document).ready(function(){
    var i18n = {
      previousMonth : 'Letzer Monat',
      nextMonth     : 'Nächster Monat',
      months        : [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      weekdays      : [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag'
      ],
      weekdaysShort : [
        'Son',
        'Mon',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa'
      ]
    };
    const options = {
      field: $(`.${unique_id}`)[0],
      i18n: i18n,
      format: 'YYYY-MM-DD'
    }
    if(!no_max_date) options.maxDate = moment().toDate();
    new Pikaday(options);
  });
}
