import Axios, { AxiosResponse } from "axios";
import { Store } from "vuex";

import { objectToFormData } from "object-to-formdata";

import PubSubInterface from "vue/applications/shared/pubsub/pub-sub-interface";
import HttpMethod from "./utils/http-method";
import RequestPayload from "./utils/payload";
import RequestAction from "./utils/request-action";
import RequestResult from "./utils/request-result";

export default abstract class Endpoint {
  public static topicName() {
    return "index";
  }

  protected $pubsub: PubSubInterface;
  protected $store: Store<any>;

  // Creates an instance, but needs the store of the application
  constructor(vuexStore: Store<any>) {
    this.$store = vuexStore;
    this.$pubsub = this.createPubSubInterface();
  }

  protected createPubSubInterface() {
    return new PubSubInterface();
  }

  protected getPrefixes(): string[] {
    return ["/"];
  }

  protected async requesting(action: RequestAction): Promise<RequestResult> {
    return await this.doRequest(
      action.path,
      action.extension,
      action.method,
      action.payload || {},
      action.params || {},
      action.asFormData,
    );
  }

  protected getCSRFToken(): string {
    return this.$store.state.gon.preparation.csrf_token;
  }

  private async doRequest(
    path: string,
    extension: string,
    method: HttpMethod,
    payload: RequestPayload,
    params: object,
    asFormData: boolean, // can be used to send files
  ): Promise<RequestResult> {
    if (typeof payload === "object" && payload.id != null) delete payload.id;

    // This is the default setting
    let data: RequestPayload | FormData = payload;
    let contentType: string | null = "application/json";

    // If we should send explicitly as formData we will transform first
    if (asFormData) {
      data = objectToFormData(payload);
      contentType = null;
    }

    const url = this._buildUrl(this.getPrefixes(), path, extension, params);
    const options = {
      data,
      headers: {
        "Content-Type": contentType,
        "X-CSRF-Token": this.getCSRFToken(),
      },
      method,
      url,
      validateStatus(status: number) {
        return (
          (status >= 200 && status < 300) || (status >= 400 && status < 500)
        );
      },
    };
    // tslint:disable-next-line: no-console
    console.log("Calling " + method.toUpperCase() + " - " + url);
    const result = await Axios(options);
    this._publish(result);
    return {
      data: result.data,
      status: result.status,
      success: isSuccessful(result.status),
    };
  }

  private _buildUrl(
    prefixes: string[],
    path: string,
    extension: string,
    params: object,
  ): string {
    let result = "";
    for (const prefix of prefixes) {
      result += prefix || "";
    }
    result += path || "";
    result += extension || ".json";
    // replace double slashes
    result = result.replace(/\/\//g, "/");
    return interpolateUrl(result, params);
  }

  private _publish(result: AxiosResponse<any>): void {
    const topics = this.$pubsub.topics.Endpoint;

    // If there is a topic matching out class name, we will select this instead of
    // the generic Endpoint.index-topic
    const topic: symbol =
      (topics as { [key: string]: any })[
        (this.constructor as typeof Endpoint).topicName()
      ] || topics.index;

    this.$pubsub.publish(topic, {
      method: result.config.method,
      path: result.config.url,
      success: isSuccessful(result.status),
    });
  }
}

// Source:
// https://github.com/supercrabtree/interpolate-url/blob/da093fb9bc9e4351a09acf6b64c2099bf287631c/index.js
const interpolateUrl = (
  url: string,
  params: { [key: string]: any },
): string => {
  if (url !== undefined && url !== null && typeof url !== "string") {
    throw new Error("url must be a string");
  }

  const result: string[] = [];

  (url || "").split("/:").forEach((segment, i, arr) => {
    if (i === 0) {
      result.push(segment);
    } else {
      const segmentMatch = segment.match(/(\w+)(?:[?*])?(.*)/) || [];
      const key: string = segmentMatch[1] || "";

      if (params[key] !== undefined) {
        result.push("/" + params[key]);
      } else {
        result.push("/:" + key);
      }

      result.push(segmentMatch[2] || "");
    }
  });

  return result.join("");
};

const isSuccessful = (statusCode: number | string): boolean => {
  let code: number;

  if (typeof statusCode === "string") code = parseInt(statusCode, 10);
  else code = statusCode;

  return code >= 200 && code < 300;
};
