import HttpMethod from "./http-method";
import RequestPayload from "./payload";

export default class RequestAction {
  public path: string = "";
  public extension: string = ".json";
  public method: HttpMethod = HttpMethod.GET;
  public payload?: RequestPayload;
  public params?: object;
  public asFormData: boolean;

  constructor(payload?: RequestPayload, params?: object) {
    this.payload = payload;
    this.params = params;
    this.asFormData = false;
  }

  public setPath(newPath: string): RequestAction {
    this.path = newPath;
    return this;
  }

  public setExtension(newExtension: string): RequestAction {
    this.extension = newExtension;
    return this;
  }

  public setMethod(newMethod: HttpMethod) {
    this.method = newMethod;
    return this;
  }

  public sendAsFormData() {
    this.asFormData = true;
    return this;
  }
}
