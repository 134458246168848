// tslint:disable:object-literal-sort-keys

export default Object.freeze({
  // You can add your own topics here.
  // This object is accessable in components via this.$pubsub.topics
  // Attention: If you listen to a topic Symbol("x"),
  //            you will also get notified if a topic
  //            Symbol("x.y") or Symbol("x.y.z") ...
  //            occurs (but not vice versa)
  Endpoint: {
    // The Endpoint class will use the Class-Name of the Endpoint as a
    // publishing topic, if it can be found here. Otherwise the index
    // topic will be used. So you should prepend the specific Class-topics
    // with "Enpoint." to make sure that listeners on the index-topic
    // get also notified when a specific topic for an specific enpoint
    // gets triggered
    index: Symbol("Endpoint"),
    VersionSetsEndpoint: Symbol("Endpoint.VersionSetsEndpoint"),
    CompetenciesEndpoint: Symbol("Endpoint.CompetenciesEndpoint"),
  },
});
