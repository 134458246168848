<template>
  <div ref="root" class="components__cnt-plot_situation_frequencies"></div>
</template>

<script>
import Plotly from "wrapper/plotly";

export default {
  mounted() {
    const patienten = {
      x: [
        "0 bis unter 1",
        "1 bis unter 3",
        "3 bis unter 6",
        "6 bis unter 10",
        "10 bis unter 18",
      ],
      y: [4178, 10732, 12978, 12407, 13839],
      marker: { color: "#CBBE9E" },
      name: "Patienten",
      type: "bar",
      textposition: "auto",
      hoverinfo: "y+name",
    };
    const vorstellungen = {
      x: [
        "0 bis unter 1",
        "1 bis unter 3",
        "3 bis unter 6",
        "6 bis unter 10",
        "10 bis unter 18",
      ],
      y: [18481, 52264, 48114, 36583, 38016],
      marker: { color: "#E79E6B" },
      name: "Vorstellungen",
      type: "bar",
      textposition: "auto",
      hoverinfo: "y+name",
    };
    const layout = {
      barmode: "group",
      title:
        "<b>Abb.1</b> <br>Vorstellungshäufigkeiten und Patientenzahlen <br>in den verschiedenen Altersgruppen",
      yaxis: {
        hoverformat: ",r",
      },
    };
    patienten.text = patienten.y;
    vorstellungen.text = vorstellungen.y;
    Plotly.newPlot(this.$refs["root"], [patienten, vorstellungen], layout, {
      modeBarButtonsToRemove: ["sendDataToCloud"],
    });
  },
};
</script>
