import * as $ from "jquery";
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

export default function(application: VueApplication, elementSelector: string) {
  // Fetch the application configuration
  const instanceConfiguration: InstanceConfiguration = {};

  // Fetch a router if there is one
  if (application.router) {
    Vue.use(VueRouter);
    instanceConfiguration.router = new VueRouter(application.router());
  }

  // Fetch a store if there is one
  if (application.store) {
    instanceConfiguration.store = application.store(Vue, Vuex);
  }

  // Load all (global) components, etc.
  if (application.initialize) {
    application.initialize(Vue);
  }

  // Call back with instanceConfiguration
  if (application.beforeMount) {
    application.beforeMount(instanceConfiguration);
  }

  // Create and mount Vue instance
  $(document).ready(() => {
    new Vue(instanceConfiguration).$mount(elementSelector);
  });
}
