import $ from "jquery";
import "tablesorter";

export default function(){
  $(document).ready(function(){
    $('.tablesorter').tablesorter({
      showProcessing: true,
      theme : 'custom'
    });
  });
}
