import $ from "jquery";

export default function(unique){
  $(document).ready(function(){
    $(`.${unique}.table-setting input`).change(function(){
      const element = $(this);
      const element_id = element.data('id');

      const elements = $(`.tr-hide-toggle-${unique}[data-id="${element_id}"]`)

      if(this.checked) elements.show(400);
      else elements.hide(400);
    });
  });
}
